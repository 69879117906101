import React from 'react';
import { Container } from "../components/container";
import SiteNav from '../components/sitenav';

const Blog = () => {
    return(
        <>
                <SiteNav></SiteNav>
        <Container>

        <h1>Blog</h1>
        <div>Work In Progress</div>
        </Container>
        </>
    )
}

export default Blog;